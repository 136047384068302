import React, { Suspense } from 'react';

import { Layout } from '@components/layout';
import { Mypage } from '@features/mypage';
import { News } from '@features/mypage/News';

const HistoryPage = () => (
  <Layout>
    <Suspense fallback={<p>Loading...</p>}>
      <Mypage>
        <News />
      </Mypage>
    </Suspense>
  </Layout>
);

export default HistoryPage;
