import React, { FC } from 'react';

import { Block } from '@components/layout/block';
import { Card } from '@components/layout/card';
import { Flex } from '@components/layout/flexbox';
import { VStack } from '@components/layout/vstack';
import { Heading2 } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';
import { Span } from '@components/typographies/span';

export const News: FC = () => (
  <Block width="100%" padding="30px 30px">
    <Heading2 fontSize="24px" lh="40px">
      お知らせ
    </Heading2>
    <VStack spacing="10px" align="flex-start">
      <Card px="20px" py="24px" width="100%" border="1.4px solid #d9d9d9" radius="5px" bg="white">
        <Block borderBottom="1.4px solid #d9d9d9" margin="0 0 10px 0" padding="0 0 10px 0">
          <Flex jc="flex-start">
            <Paragraph fontSize="16px">2022/8/21</Paragraph>
            <Span color="#F39800" fontSize="40px" lineHeight="16px">
              ・
            </Span>
            <Paragraph fontSize="16px" fontWeight="700">
              お友達紹介機能が追加されました
            </Paragraph>
          </Flex>
        </Block>
        <Paragraph fontSize="16px">
          テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
          テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
        </Paragraph>
      </Card>
    </VStack>
  </Block>
);
